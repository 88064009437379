<template>
  <div>
    <div v-if="screenType === 'desktop'">
      <v-progress-circular
          v-if="isLoading"
          :size="50"
          :width="10"
          color="#ffbe66"
          indeterminate
      ></v-progress-circular>
      <div v-else>
        <v-alert
            v-model="NOTIFICATION_STATUS"
            :color="NOTIFICATION_COLOR"
            elevation="24"
            type="success"
            dismissible
            width="100%"
            style="position: fixed; z-index: 99999"
        >{{ NOTIFICATION }}
        </v-alert>
        <v-row class="ml-14">
          <v-col cols="12" class="text-left">
            <v-btn class="breadcrumb text-capitalize" text to="/my-page">My Page
            </v-btn>
            <v-icon>mdi-chevron-right</v-icon>
            <v-btn class="text-capitalize breadcrumb"
                   text
                   @click="$router.push('/books')"
            >
              Book Library
            </v-btn>
            <v-icon>mdi-chevron-right</v-icon>
            <v-btn class="text-capitalize breadcrumb"
                   text @click="goToCat('parent')">
              {{ categories.parent.title }}
            </v-btn>
            <v-icon>mdi-chevron-right</v-icon>
            <v-btn class="text-capitalize breadcrumb"
                   text @click="goToCat('child')">
              {{ categories.title }}
            </v-btn>
            <v-icon>mdi-chevron-right</v-icon>
            <v-btn class="text-capitalize breadcrumb-current"
                   text>
              {{ title }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-left ml-16">
            <v-menu style="z-index: 999999!important;" offset-y-0 top rounded>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on" class="text-icon-custom text-capitalize">
                  <v-icon class="mr-1" size="1.5vw">mdi-theme-light-dark</v-icon>
                  Theme
                </v-btn>
              </template>
              <v-list>
                <v-list-item :style="{backgroundColor: theme === 'light' ? '#ffbe66' : ''}"
                             @click="changeThemeTo('light')">
                  <v-list-item-icon>
                    <v-icon>mdi-weather-sunny</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Light</v-list-item-title>
                </v-list-item>
                <v-list-item :style="{backgroundColor: theme === 'dark' ? '#ffbe66' : ''}"
                             @click="changeThemeTo('dark')">
                  <v-list-item-icon>
                    <v-icon>mdi-weather-night</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Dark</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn text class="text-icon-custom text-capitalize" @click="bookmarkBook()" v-if="book.bookmarked">
              <v-icon class="mr-1" size="1.5vw">mdi-bookmark</v-icon>
              Bookmark
            </v-btn>
            <v-btn text class="text-icon-custom text-capitalize" @click="bookmarkBook()" v-else>
              <v-icon class="mr-1" size="1.5vw">mdi-bookmark-outline</v-icon>
              Bookmark
            </v-btn>
            <comment class="d-inline-block">
              <template v-slot:button>
                <v-btn text class="text-icon-custom text-capitalize">
                  <v-icon class="mr-1" size="1.5vw">mdi-message</v-icon>
                  Note
                </v-btn>
              </template>
            </comment>
            <report class="d-inline-block" :entity_id="$route.params.id" entity_type="video">
              <template v-slot:button>
                <v-btn text class="text-icon-custom text-capitalize">
                  <v-icon class="mr-1" size="1.5vw">mdi-text</v-icon>
                  Report
                </v-btn>
              </template>
            </report>
            <v-btn text class="text-icon-custom text-capitalize" to="/bookmarks/book">
              <v-icon class="mr-1" size="1.5vw">mdi-bookshelf</v-icon>
              My Pages
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-left ml-16 mb-6">
            <v-btn elevation="0" color="#f2f3f7" class="text-capitalize custom-radius-button"
                   :to="'/related/videos/' + $route.params.id">
              <v-icon class="mr-1" size="1.5vw">mdi-video</v-icon>
              Related Videos
            </v-btn>
            <!--          <v-btn elevation="0" color="#f2f3f7" class="text-capitalize custom-radius-button"
                             :to="'/related/questions/' + this.$route.params.id">
                        <v-icon class="mr-1" size="1.5vw">mdi-help</v-icon>
                        Related Questions
                      </v-btn>-->
            <v-btn elevation="0" color="#f2f3f7" class="text-capitalize custom-radius-button"
                   :to="'/related/animations/' + $route.params.id">
              <v-icon class="mr-1" size="1.5vw">mdi-animation</v-icon>
              Related Animations
            </v-btn>
          </v-col>
          <!--        <v-col sm="2">-->
          <!--          <v-btn text class="BookNav" @click="toggleBookmark(book)" v-if="book.bookmarked">-->
          <!--            <v-icon>mdi-bookmark</v-icon>-->
          <!--          </v-btn>-->
          <!--          <v-btn text class="BookNav" @click="toggleBookmark(book)" v-else>-->
          <!--            <v-icon>mdi-bookmark-outline</v-icon>-->
          <!--          </v-btn>-->
          <!--          <v-btn text class="BookNav" @click="$router.push('/order/'+book.id)">-->
          <!--            <v-icon>mdi-cart</v-icon>-->
          <!--          </v-btn>-->
          <!--        </v-col>-->
          <!--        &lt;!&ndash;        <v-col sm="2">&ndash;&gt;-->
          <!--        &lt;!&ndash;          <v-btn :to="'/related/questions/' + this.$route.params.id" text class="text&#45;&#45;secondary">Related Questions</v-btn>&ndash;&gt;-->
          <!--        &lt;!&ndash;        </v-col>&ndash;&gt;-->
          <!--        <v-col sm="2">-->
          <!--          <v-btn :to="'/related/animations/' + this.$route.params.id" text class="BookNav">Related Animation</v-btn>-->
          <!--        </v-col>-->
          <!--        <v-col sm="2">-->
          <!--          <v-btn :to="'/related/videos/' + this.$route.params.id" text class="BookNav">Related Videos</v-btn>-->
          <!--        </v-col>-->
          <!--        <v-col sm="2">-->
          <!--          <v-btn text class="BookNav" to="/bookmarks/book">My Books</v-btn>-->
          <!--        </v-col>-->
          <!--        <v-col sm="2">-->
          <!--          <v-dialog-->
          <!--              style="z-index: 99999"-->
          <!--              v-model="qrcodeDialog"-->
          <!--              width="500"-->
          <!--          >-->
          <!--            <template v-slot:activator="{ on, attrs }">-->
          <!--              <v-btn-->
          <!--                  text-->
          <!--                  class="BookNav"-->
          <!--                  v-bind="attrs"-->
          <!--                  v-on="on"-->
          <!--              >-->
          <!--                QR Code-->
          <!--              </v-btn>-->
          <!--            </template>-->
          <!--            <div class="card" style="background-color: white; padding: 6px; border-radius: 35px">-->
          <!--              <v-img style="border-radius: 35px!important;" title="qrcode" alt="qrcode" :src="baseURL + this.qrcode"-->
          <!--                     width="500px"></v-img>-->
          <!--            </div>-->
          <!--          </v-dialog>-->
          <!--        </v-col>-->
        </v-row>
        <div>
          <vue-pdf-app class="custom-margin-x" :theme="theme" :config="config"
                       style="margin: 10px; border-radius: 10px!important;"
                       :style="{ height: mobile ? '60vh' : '95vh' }" :pdf="path"></vue-pdf-app>
        </div>
        <!--      <v-container class="mt-10">-->
        <!--        <v-card>-->
        <!--          <v-card-title>-->
        <!--            Store a Comment-->
        <!--          </v-card-title>-->
        <!--          <v-card-text>-->
        <!--            <v-text-field-->
        <!--                v-model="newComment.title"-->
        <!--                dense-->
        <!--                filled-->
        <!--                rounded-->
        <!--                label="Title"-->
        <!--            >-->
        <!--            </v-text-field>-->
        <!--            <v-text-field-->
        <!--                v-model="newComment.body"-->
        <!--                dense-->
        <!--                filled-->
        <!--                rounded-->
        <!--                label="Comment"-->
        <!--            >-->
        <!--            </v-text-field>-->
        <!--          </v-card-text>-->
        <!--          <v-card-actions>-->
        <!--            <v-btn rounded color="#39bf88" height="60px" style="box-shadow: none; color: white" @click="storeComment">-->
        <!--              Store Comment-->
        <!--            </v-btn>-->
        <!--          </v-card-actions>-->
        <!--        </v-card>-->
        <!--      </v-container>-->
        <!--      <div class="text-center">-->
        <!--        <v-dialog-->
        <!--            style="z-index: 99999"-->
        <!--            v-model="dialog"-->
        <!--            width="500"-->
        <!--        >-->
        <!--          <template v-slot:activator="{ on, attrs }">-->
        <!--            <v-btn-->
        <!--                class="text-capitalize"-->
        <!--                rounded-->
        <!--                height="60px"-->
        <!--                style="box-shadow: none; color: white"-->
        <!--                color="#ffbe66"-->
        <!--                dark-->
        <!--                v-bind="attrs"-->
        <!--                v-on="on"-->
        <!--                @click="getComments"-->
        <!--            >-->
        <!--              See Comments-->
        <!--            </v-btn>-->
        <!--          </template>-->
        <!--          <v-card>-->
        <!--            <v-card-title class="text-h5 grey lighten-2">-->
        <!--              Comments-->
        <!--            </v-card-title>-->
        <!--            <v-card-text v-for="comment in comments" :key="comment.id">-->
        <!--              <h4>{{ comment.title }}</h4>-->
        <!--              <p>{{ comment.body }}</p>-->
        <!--              <hr>-->
        <!--              <p>{{ comment.created_at }}</p>-->
        <!--              <v-divider></v-divider>-->
        <!--            </v-card-text>-->
        <!--            <v-card-actions>-->
        <!--              <v-spacer></v-spacer>-->
        <!--              <v-btn-->
        <!--                  color="primary"-->
        <!--                  text-->
        <!--                  @click="dialog = false"-->
        <!--              >-->
        <!--                Close-->
        <!--              </v-btn>-->
        <!--            </v-card-actions>-->
        <!--          </v-card>-->
        <!--        </v-dialog>-->
        <!--      </div>-->
      </div>
    </div>
    <div v-else style="margin-top: 7vh">
      <v-progress-circular
          v-if="isLoading"
          size="90%"
          width="5"
          color="#ffbe66"
          indeterminate
      ></v-progress-circular>
      <div v-else>
        <v-alert
            v-model="NOTIFICATION_STATUS"
            :color="NOTIFICATION_COLOR"
            elevation="24"
            type="success"
            dismissible
            width="100%"
            style="position: fixed; z-index: 99999"
        >{{ NOTIFICATION }}
        </v-alert>
        <v-row class="breadcrumb-container-mobile">
          <v-col cols="12" class="text-left">
            <v-btn class="breadcrumb-mobile text-capitalize" text to="/my-page">My Page
            </v-btn>
            <v-icon small style="margin-left: -16px">mdi-chevron-right</v-icon>
            <v-btn class="text-capitalize breadcrumb-mobile"
                   style="margin-left: -15px"
                   text
                   @click="$router.push('/books')"
            >
              Book Library
            </v-btn>
            <v-icon small style="margin-left: -16px">mdi-chevron-right</v-icon>
            <v-btn class="text-capitalize breadcrumb-mobile"
                   style="margin-left: -15px"
                   text @click="goToCat('parent')">
              {{ categories.parent.title }}
            </v-btn>
            <v-icon small style="margin-left: -16px">mdi-chevron-right</v-icon>
            <v-btn
                style="margin-left: -15px"
                class="text-capitalize breadcrumb-mobile"
                text @click="goToCat('child')">
              {{ categories.title }}
            </v-btn>
            <v-icon small style="margin-left: -16px">mdi-chevron-right</v-icon>
            <v-btn style="margin-left: -15px"
                   class="text-capitalize breadcrumb-current-mobile"
                   text>
              {{ title }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-7">
            <v-divider></v-divider>
            <v-divider></v-divider>
            <v-divider></v-divider>
            <v-divider></v-divider>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="menu-bg-mobile">
            <v-row>
              <v-col cols="2" class="white--text ml-3" @click="bookmarkBook()">
                Bookmark
              </v-col>
              <v-col cols="2" class="white--text">
                <comment class="d-inline-block">
                  <template v-slot:button>
                    <v-btn text class="white--text text-capitalize"
                           style="letter-spacing: normal;margin-left: -2px;margin-top: -7px;font-family: Blinker-Bold!important;font-size: 17px">
                      Note
                    </v-btn>
                  </template>
                </comment>
              </v-col>
              <v-col cols="2" class="white--text">
                <report class="d-inline-block" :entity_id="$route.params.id" entity_type="book">
                  <template v-slot:button>
                    <v-btn text class="white--text text-capitalize"
                           style="letter-spacing: normal;margin-left: -18px;margin-top: -7px;font-family: Blinker-Bold!important;font-size: 17px">
                      Report
                    </v-btn>
                  </template>
                </report>
              </v-col>
              <v-col cols="2" class="white--text" @click="$router.push('/bookmarks/book')">
                MyPages
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="menu-bg-mobile">
            <v-row>
              <v-col cols="6" class="white--text" style="font-size: 17px"
                     @click="$router.push('/related/videos/'+$route.params.id)"
              >
                Related Videos
              </v-col>
              <v-col cols="6" class="white--text text-left" style="font-size: 17px"
                     @click="$router.push('/related/animations/'+$route.params.id)"
              >
                Related Animations
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div class="mt-8 mb-16">
          <vue-pdf-app class="px-1 mx-4 mb-16" :theme="theme" :config="config"
                       style="margin: 2px; border-radius: 10px!important;height: 65vh"
                       :pdf="path"></vue-pdf-app>
        </div>
        <v-row class="my-16">
          <v-col></v-col>
        </v-row>
        <!--      <v-container class="mt-10">-->
        <!--        <v-card>-->
        <!--          <v-card-title>-->
        <!--            Store a Comment-->
        <!--          </v-card-title>-->
        <!--          <v-card-text>-->
        <!--            <v-text-field-->
        <!--                v-model="newComment.title"-->
        <!--                dense-->
        <!--                filled-->
        <!--                rounded-->
        <!--                label="Title"-->
        <!--            >-->
        <!--            </v-text-field>-->
        <!--            <v-text-field-->
        <!--                v-model="newComment.body"-->
        <!--                dense-->
        <!--                filled-->
        <!--                rounded-->
        <!--                label="Comment"-->
        <!--            >-->
        <!--            </v-text-field>-->
        <!--          </v-card-text>-->
        <!--          <v-card-actions>-->
        <!--            <v-btn rounded color="#39bf88" height="60px" style="box-shadow: none; color: white" @click="storeComment">-->
        <!--              Store Comment-->
        <!--            </v-btn>-->
        <!--          </v-card-actions>-->
        <!--        </v-card>-->
        <!--      </v-container>-->
        <!--      <div class="text-center">-->
        <!--        <v-dialog-->
        <!--            style="z-index: 99999"-->
        <!--            v-model="dialog"-->
        <!--            width="500"-->
        <!--        >-->
        <!--          <template v-slot:activator="{ on, attrs }">-->
        <!--            <v-btn-->
        <!--                class="text-capitalize"-->
        <!--                rounded-->
        <!--                height="60px"-->
        <!--                style="box-shadow: none; color: white"-->
        <!--                color="#ffbe66"-->
        <!--                dark-->
        <!--                v-bind="attrs"-->
        <!--                v-on="on"-->
        <!--                @click="getComments"-->
        <!--            >-->
        <!--              See Comments-->
        <!--            </v-btn>-->
        <!--          </template>-->
        <!--          <v-card>-->
        <!--            <v-card-title class="text-h5 grey lighten-2">-->
        <!--              Comments-->
        <!--            </v-card-title>-->
        <!--            <v-card-text v-for="comment in comments" :key="comment.id">-->
        <!--              <h4>{{ comment.title }}</h4>-->
        <!--              <p>{{ comment.body }}</p>-->
        <!--              <hr>-->
        <!--              <p>{{ comment.created_at }}</p>-->
        <!--              <v-divider></v-divider>-->
        <!--            </v-card-text>-->
        <!--            <v-card-actions>-->
        <!--              <v-spacer></v-spacer>-->
        <!--              <v-btn-->
        <!--                  color="primary"-->
        <!--                  text-->
        <!--                  @click="dialog = false"-->
        <!--              >-->
        <!--                Close-->
        <!--              </v-btn>-->
        <!--            </v-card-actions>-->
        <!--          </v-card>-->
        <!--        </v-dialog>-->
        <!--      </div>-->
      </div>
    </div>

  </div>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import localStorage from "reactive-localstorage";
import Report from "../components/Report";
import Comment from "../components/Comment";

let PAGENUMBER = null;

localStorage.on('change', (key, value) => {
  if (key == 'pdfjs.history' && JSON.parse(value).files[0].page != PAGENUMBER) {
    PAGENUMBER = JSON.parse(value).files[0].page
    window.axios.post(`api` + window.location.pathname + '/update-progress', {
      currentPage: PAGENUMBER
    })
  }
});

export default {
  name: "Book",
  components: {
    VuePdfApp,
    Report,
    Comment
  },
  data() {
    return {
      screenType: null,
      categories: null,
      isLoading: true,
      book: {
        id: null,
        bookmarked: null,
      },
      title: null,
      content: null,
      path: null,
      comments: null,
      newComment: {
        title: null,
        body: null,
      },
      dialog: false,
      config: {
        toolbar: {
          toolbarViewerRight: {
            download: false,
            openFile: false,
          },
        }
      },
      theme: 'light',
      qrcode: null,
      qrcodeDialog: false,
    }
  },
  created() {
    window.axios.get(`api/books/${this.$route.params.id}`)
        .then((data) => {
          this.categories = data.data.data.categories[0]
          this.book.id = data.data.data.id
          this.title = data.data.data.title
          this.content = data.data.data.content
          this.path = this.baseURL + data.data.data.path
          this.comments = data.data.data.comments
          this.qrcode = data.data.data.qrcode
          this.book.bookmarked = data.data.data.bookmarked
          this.isLoading = false
        }).catch((error) => {
      if (error.response.status == 403) {
        this.isLoading = false
        this.NOTIFICATION = 'You must have a digital plan to view products.';
        this.NOTIFICATION_STATUS = true;
        this.NOTIFICATION_COLOR = 'red'
        let _this = this
        setTimeout(() => {
          _this.$router.push('/plans')
        }, 3000)
      }
    })
  },
  mounted() {
    this.getScreenType()
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    bookmarkBook() {
      window.axios.post(`api/bookmarks/${this.$route.params.id}`).then(() => {
        this.book.bookmarked = !this.book.bookmarked
      })
    },
    goToCat(type) {
      this.$router.push({
        name: 'Animations', params: {
          category: type === 'child' ? this.categories : this.categories.parent,
          type: type,
          selectedCategoryTitle: this.categories.parent.title,
          selectedSubCategoryTitle: this.categories.title,
        }
      })
    },
    storeComment() {
      window.axios.post(`api/comment/${this.$route.params.id}`, {
        title: this.newComment.title,
        body: this.newComment.body
      }).then(() => {
        this.NOTIFICATION = 'Comment Stored Successfully!';
        this.NOTIFICATION_STATUS = true;
        this.NOTIFICATION_COLOR = 'green'
      })
    },
    getComments() {
      window.axios.get(`api/comments/${this.$route.params.id}`).then((data) => {
        this.comments = data.data.data
      })
    },
    changeThemeTo(theme) {
      this.theme = theme
    }
  }
}
</script>

<style scoped>
.menu-bg-mobile {
  background-color: #a8a8a8;
}

.breadcrumb-container-mobile {
  position: absolute;
  z-index: 10;
  width: 104%;
  left: 0;
  top: 80px;
  height: 15vh;
  background-color: #f6f6f9 !important;
}

.breadcrumb-mobile {
  font-size: 13px;
  color: #848e99;
}

.breadcrumb-current-mobile {
  font-size: 13px;
  color: #39bf88;
}

.BookNav {
  color: #a8a8a8;
}

.custom-margin-x {
  margin: 0 9vw !important;
}

.breadcrumb {
  font-size: 0.9vw !important;
  color: #848e99;
}

.breadcrumb-current {
  font-size: 0.9vw !important;
  color: #39bf88;
}

.breadcrumb-container {
  margin-top: -6vh !important;
  margin-left: 8.1vw !important;
}

.custom-radius-button {
  color: #a8a8a8 !important;
  border-radius: 0.8vw !important;
  padding: 1.2vw !important;
  margin-right: 0.8vw !important;
  font-size: 1vw !important;
}

.custom-radius-button:hover {
  color: white !important;
  background-color: #34c288 !important;
}

.text-icon-custom {
  color: #a8a8a8 !important;
  font-size: 0.8vw !important;
}

.text-icon-custom:hover {
  color: #34c288 !important;
  background-color: white !important;
}
</style>